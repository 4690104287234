import React, { Component } from 'react';
import SmartSteps from "../contracts/SmartSteps.json";

class Header extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      const {isHome,user_address,user_address_short} = this.props;
      return (



      <header>
        <div className="container">
          <div className="header_box">
            <nav className="navbar navbar-expand-md">
              <a className="navbar-brand" href="/">
                <img className="header_logo" src="img/logo.png" alt="" />
              </a>
              <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#collapsibleNavbar" aria-expanded="false">
                <img src="img/menu.png" alt="" />
              </button>
            
              <div className="navbar-collapse collapse" id="collapsibleNavbar">
                <ul className="navbar-nav mr-auto">
                    {/* {isHome ? (
                     <li className="nav-item">
                        <a href="/">Home</a>                                        
                      </li> 
                    ) : (null)} */}
                    
                    {/* <li className="nav-item">
                        <a target={'_blank'} href="https://howtoplay.smartstep.live">How to play</a>                                        
                    </li> */}
                    <li className="nav-item">
                        <a target={'_blank'} href="https://whitepaper.smartstep.live/">Whitepaper</a>
                    </li>                                            
                    <li className="nav-item">
                        <a target={'_blank'} href="https://roadmap-explanatory.smartstep.live/">Roadmap</a>                                        
                    </li>

                    {!isHome?(
                      <li className="nav-item">
                        <a target={'_blank'} href={`https://termsconditions.smartstep.live`}>Terms & Conditions</a>                                        
                      </li>
                    ):(null)}
                    
                    {!isHome?(
                    <li className="nav-item">
                        <a target={'_blank'} href={`https://smartstep.live/app/en/binary-tree/${user_address}`}>Binary Tree</a>                                        
                    </li>
                    ):(null)}
                </ul>
               
                {isHome?(
                  <ul className="navbar-nav">
                  
                    <li className="nav-item">
                        <a className="border_btn" href="/wallet"><img src="img/wallet.png" alt="" />Wallet</a>
                    </li>


                    <li className="nav-item dropdown main_btn">
                      <a className="dropdown-toggle" href="#"  data-toggle="dropdown">
                      View Contracts
                      </a>
                      <div className="dropdown-menu icon_dropdown">
                      <a className="dropdown-item" target="_blank" href={`https://bscscan.com/address/${SmartSteps.networks[56].address}`}>Main Contract</a>
                            <a className="dropdown-item" target="_blank" href={`https://bscscan.com/token/0x9Ef40128D14f189914731aA13077C23f42cAADb9`}>Token Contract</a>
                            {/* <a className="dropdown-item" target="_blank" href={`https://bscscan.com/address/0x62ffBF675d5a30fDad84DC9Ac097bB75A28cb109`}>Token Contract old</a> */}
                            <a className="dropdown-item" target="_blank" href={`https://bscscan.com/address/0xdD8cB1cAf6C533B389Ec53685908367b19A93dD3`}>Swap contract</a>
                            {/* <a className="dropdown-item" target="_blank" href={`https://bscscan.com/address/0x9d94CD80bb9fC5359F624039f5d3d7824fBD030D`}>Swap contract old</a> */}
                            <a className="dropdown-item" target="_blank" href={`https://bscscan.com/address/0x005EdE9483cea02C9AB07438d249b187e162fdD9`}>NFT Contract</a>
                       </div>
                    </li>


                    {/* <li className="nav-item">
                        <a className="main_btn" target="_blank" href={`https://bscscan.com/address/${SmartSteps.networks[56].address}`}>View Contract</a>
                    </li> */}

                  </ul>
                 ):(
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown main_btn">
                          <a className="dropdown-toggle" href="#"  data-toggle="dropdown">
                          View Contracts
                          </a>
                          <div className="dropdown-menu icon_dropdown">
                              <a className="dropdown-item" target="_blank" href={`https://bscscan.com/address/${SmartSteps.networks[56].address}`}>Main Contract</a>
                              <a className="dropdown-item" target="_blank" href={`https://bscscan.com/address/0x62ffBF675d5a30fDad84DC9Ac097bB75A28cb109`}>Token Contract</a>
                              <a className="dropdown-item" target="_blank" href={`https://bscscan.com/address/0x9d94CD80bb9fC5359F624039f5d3d7824fBD030D`}>Swap contract</a>
                              <a className="dropdown-item" target="_blank" href={`https://bscscan.com/address/0x005EdE9483cea02C9AB07438d249b187e162fdD9`}>NFT Contract</a>
                          </div>
                        </li>

                    </ul>
                    )}
                
              </div>
            </nav>
            
          </div>
        </div>
      </header>

      
      );
    }
  }

  export default Header;