import React, { Component } from 'react';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <footer className="pt-5 pb-5">
            <div className="container">
            <div className="row align-items-center">
                <div className="col-md-3 col-lg-3 col-sm-4">
                <img className="footer_logo mb-4" src="img/logo.png" alt="" />
                </div>
                <div className="col-md-9 col-lg-9 col-sm-8">
                <p className="mb-0">We are a global crypto community promoting move-to-earn. Our platform rewards you in return for leading an active and healthy lifestyle. At Smartsteps we envision encouraging millions if not billions of crypto enthusiasts towards a healthy lifestyle, connecting them to the Web3 platform to do something outstanding and contribute positively towards carbon neutrality. The majority of the revenue generated by Smartsteps web3 lifestyle platform users is distributed to the users and all of its assets are owned by them. Smart Steps – your web3 gateway to revolutionize your health and get rewarded.</p>
                </div>
            </div>
            <hr />
            <p className="mb-0 text-center white-text">© Smart Steps 2023 - ALL rights reserved</p>
            </div>
        </footer>
      );
    }
  }

  export default Footer;