import React, { Component } from 'react';
import Header from "./header";
import Footer from "./footer";


class Frame extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
        const {withHeader, withFooter, isHome, user_address, user_address_short } = this.props;
      return (
        <div>

          {withHeader && <Header isHome={isHome} user_address={user_address} user_address_short={user_address_short}></Header> } 
         
          {this.props.children}

          {withFooter &&  <Footer></Footer>}        

        </div>
      );
    }
  }

  export default Frame;