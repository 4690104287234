import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

class Home extends React.Component {
    constructor(props) {
      super(props);
     
    }

    render() {   
      return (
        <Frame withFooter={true} withHeader={true} isHome={true}>
          
        <section className="banner_section">
            <div className="container">
            <div className="row">
                <div className="col-lg-10">
                <div className="banner_text">
                    <h5>Revolution In Fitness! </h5>
                    <h1>
                    <span className="text-break text_line"> LET’s RUN</span> <span className="padding_left text_line">LET’s </span>  <span className="text_bg">EARN!</span>
                    </h1>
                </div>
                </div>
            </div>
            </div>
        </section>

        <section className="pt-5 pb-5">
            <div className="container">
            <div className="row no-gutters">
                <div className="col-lg-3 col-md-4">
                <div className="banner_step">
                    <div className="banner_step_img"></div>
                    <h2>01</h2>
                    <h4>Step On</h4>
                    <p>Get-set-go, prep yourself to move. Take your fitness to the next level with Smartsteps. Start running and walking now.</p>
                </div>
                </div>
                <div className="col-lg-3 col-md-4">
                <div className="banner_step">
                    <div className="banner_step_img"></div>
                    <h2>02</h2>
                    <h4>Activity Log</h4>
                    <p>Start running or walking to convert each step into a fraction of crypto. Smartsteps is opportune for fitness and finance.</p>
                </div>
                </div>
                <div className="col-lg-3 col-md-4">
                <div className="banner_step dark">
                    <div className="banner_step_img"></div>
                    <h2>03</h2>
                    <h4>Fetch Token</h4>
                    <p>Walk or run and start earning Smartstep tokens instantaneously and get them transferred to your wallet. Step more to earn more.</p>
                </div>
                </div>
            </div>
            </div>
            <div className="pt-5 pb-0">
            <div className="simple-marquee-container">
                <div className="marquee">
                <div className="marquee-content-items">
                    <h2>  
                    <span>Let’s move further into </span>
                    <span>a better shape and </span>
                    <span>a better world </span>
                    <span>with Smartsteps. </span>
                    </h2>
                </div>
                </div>
            </div>
            </div>
        </section>

        <section className="background1">
            <div className="white_slider">
            <div className="row">
                <div className="col-md-7 col-lg-7">
                <div id="img_slider" className="carousel slide" data-ride="carousel">
                    <ul className="carousel-indicators">
                    <li data-target="#img_slider" data-slide-to="0" className="active"></li>
                    <li data-target="#img_slider" data-slide-to="1"></li>
                    </ul>
                    <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="img/slide1.jpg" alt="" />
                    </div>
                    <div className="carousel-item">
                        <img src="img/slide2.jpg" alt="" />
                    </div>
                    </div>
                
                </div>
                </div>
                <div className="col-md-5 col-lg-5">
                <div className="about_text_p">
                    <div className="all_heading mb-4 dark_heading">
                    <h5>About Us</h5>
                    <h2><span className="text_line">GET FIT AND EARN WITH </span> <span className="text_bg">SMARTSTEPS!</span> </h2>
                    </div> 
                    <p className="mb-0 font-17">Smartsteps is the most advanced fitness platform that rewards you with “Smartsteps” tokens when you move in the real world while being connected to the metaverse. Steppers will be rewarded tokens for walking or running everywhere. The more you run, the more you earn!</p>
                </div>
                </div>
            </div>
            </div>
        </section>

        <section className="pt-5 pb-3">
            <div className="container">
            <div className="row">
                <div className="col-md-4 col-lg-4">
                <div className="point_box">
                    <h2><span>01.</span> Decentralized Affair</h2>
                    <p>Smart contract-based conditions are programmed to check decentralized transactions without the involvement of an intermediary. </p>
                </div>
                <div className="point_box">
                    <h2><span>02.</span> Uninterrupted Blocks</h2>
                    <p>Seamlessly interact and share data across chains through dApps to notify users whenever two chains are bridged or connected.</p>
                </div>
                </div>
                <div className="col-md-4 col-lg-4">
                <div className="position-relative">
                    <img className="run_img_position" src="img/h1_run.png" alt="" />
                    <h3 className="run_text_position">WHY STEP WITH US?</h3>
                </div>
                </div>
                <div className="col-md-4 col-lg-4 text-right">
                <div className="point_box">
                    <h2> Footwear Upgrade <span>03.</span></h2>
                    <p>This move-to-earn platform has inbuilt gaming elements letting you upgrade your footwear and artifacts to earn rewards in tokens.</p>
                </div>
                <div className="point_box">
                    <h2> Instant Earnings <span>04.</span></h2>
                    <p>With Smartsteps you never have to wait for your earnings to be deposited in your wallet. Set up your wallet for direct token deposit.</p>
                </div>
                </div>
            </div>
            </div>
        </section>

        <section className="">
            <div className="container">
            <div className="line_text">
                <h2>Let’s pledge to stay fit with Smartsteps, a revolutionary capital-generating platform.</h2>
            </div>
            </div>
        </section> 

        <section className="pt-5 pb-5">
            <div className="container">
            <div className="row align-items-center">
                <div className="col-md-6 col-lg-6">
                <img className="h2_run_img" src="img/h2_run.png" alt="" />
                </div>
                <div className="col-md-6 col-lg-6">
                <div className="all_heading mb-4">
                    <h5>Earning Made Simple</h5>
                    <h2><span className="text_line">RUN WITH US!</span> </h2>
                </div>
                <p>Smartsteps is changing lives by helping people get fit and earn while they are running and walking, and we incentivize a healthier & active lifestyle with token rewards. Join us to make the earth greener.</p>
                
                <div className="row">
                    <div className="col-md-6 col-lg-6">
                    <div className="Mission_box">
                        <h2>Our Mission</h2>
                        <p>To motivate individuals and families to adopt an active & healthy lifestyle for a fit body.</p>
                    </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                    <div className="Mission_box">
                        <h2>Our Vision</h2>
                        <p>To be the planet’s most fitness-centric platform and find revolutionary ways of earning.</p>
                    </div>
                    </div>
                </div>
                
                </div>
            </div>
            </div>
        </section>

        <section className="features_bg">
            <div className="container">
            <div className="all_heading mb-5 middle_heading text-center">
                <h5 className="mb-3">Achieve Perfection</h5>
                <h2><span className="text_line">INNOVATIVE FEATURES FOR EARNING</span></h2>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-6 col-lg-4">
                <div className="features_box">
                    <img src="img/icon1.png" alt="" />
                    <h3>Activity Log</h3>
                    <p>Track your progress while you run and walk. Track how Smartsteps is improving your activity and earnings.</p>
                </div>
                </div>
                <div className="col-md-4 col-sm-6 col-lg-4">
                <div className="features_box">
                    <img src="img/icon2.png" alt="" />
                    <h3>Digital Wallet</h3>
                    <p>Step more to deposit more tokens to your wallet and track your balance while you run and walk. </p>
                </div>
                </div>
                <div className="col-md-4 col-sm-6 col-lg-4">
                <div className="features_box">
                    <img src="img/icon3.png" alt="" />
                    <h3>Community Activity</h3>
                    <p>Be a part of the Smartsteps community, interact, and encourage others to run and earn tokens.</p>
                </div>
                </div>
                <div className="col-md-4 col-sm-6 col-lg-4">
                <div className="features_box">
                    <img src="img/icon4.png" alt="" />
                    <h3>Move-2-earn Ecosystem</h3>
                    <p>Smartsteps maintains a stable earning ecosystem that encourages carbon neutrality and promotes earning.</p>
                </div>
                </div>
                <div className="col-md-4 col-sm-6 col-lg-4">
                <div className="features_box">
                    <img src="img/icon5.png" alt="" />
                    <h3>Blockchain Network</h3>
                    <p>Smartsteps is a platform developed over a blockchain network with in-game activities promoting fitness.</p>
                </div>
                </div>
                <div className="col-md-4 col-sm-6 col-lg-4">
                <div className="features_box">
                    <img src="img/icon6.png" alt="" />
                    <h3>Secured Network</h3>
                    <p>Smartsteps is based on blockchain and is integrated with smart contracts which makes it more secure. </p>
                </div>
                </div>
            </div>
            </div>
        </section>

        <section className="pt-4 pb-4 app_bg">
            <div className="container">
            <div className="row align-items-center">
                <div className="col-md-5 col-lg-5">
                <div className="all_heading mb-4">
                    <h5 className="mb-3">Coming soon!</h5>
                    <h2><span className="text_line">DOWNLOAD</span> <span className="heading_solid">THE APP</span></h2>
                </div>
                </div>
                <div className="col-md-3 col-lg-3">
                <div className="fugu-app-box">
                    <a className="fugu-app-btn" href="#"><img src="img/play-store.png" alt="" /></a>
                    <a className="fugu-app-btn" href="#"><img src="img/app-store.png" alt="" /></a>
                </div>
                </div>
                <div className="col-md-4 col-lg-4">
                <img className="app_mobile" src="img/app_mobile.png" alt="" />
                </div>
            </div>
            </div>
        </section>


        <section className="pt-5 pb-5">
            <div className="container">
            <div className="row">
                <div className="col-md-4 col-lg-4">
                <img className="mobile1_img" src="img/mobile1.png" alt="" />
                </div>
                <div className="col-md-8 col-lg-8">
                <div>
                    <div className="all_heading mb-4">
                    <h5 className="mb-3">Smartsteps FAQ</h5>
                    <h2><span className="text_line">FREQUENTLY ASKED QUESTIONS</span></h2>
                    </div>
                    <div className="accordion_border">
                    <div className="accordion_head">
                        <span className="plusminus"><i className="fa fa-plus-circle"></i></span>
                        What is SmartSteps? 
                    </div>
                    <div className="accordion_body">
                        <p>Smartsteps is a move-to-earn fitness platform built around walking and running. Once the users sign up on the platform, they have geared up to mint tokens while they walk and run.</p>
                    </div>
                    </div>
                    <div className="accordion_border">
                    <div className="accordion_head">
                        <span className="plusminus"><i className="fa fa-plus-circle"></i></span>
                        How do I earn? 
                    </div>
                    <div className="accordion_body">
                        <p>Smartsteps tokens can be earned through the platform while you forward a step when you run or walk. Your physical stamina will be awarded. It is as simple as that. </p>
                    </div>
                    </div>
                    <div className="accordion_border">
                    <div className="accordion_head">
                        <span className="plusminus"><i className="fa fa-plus-circle"></i></span>
                        What is move-to-earn? 
                    </div>
                    <div className="accordion_body">
                        <p>Move-to-earn is a new way of using technology to reward people for getting up and opting for a healthier lifestyle. Move-to-earn platforms can be a great way to earn tokens. </p>
                    </div>
                    </div>
                    <div className="accordion_border">
                    <div className="accordion_head">
                        <span className="plusminus"><i className="fa fa-plus-circle"></i></span>
                        What is Web 3? 
                    </div>
                    <div className="accordion_body">
                        <p>Web 3, is the next generation of the web where the platform users will be connected via a decentralized network that provides access to their own data. </p>
                    </div>
                    </div>
                    <div className="accordion_border">
                    <div className="accordion_head">
                        <span className="plusminus"><i className="fa fa-plus-circle"></i></span>
                        How old do I have to be to join? 
                    </div>
                    <div className="accordion_body">
                        <p>Anyone can open an account on Smartstep - there’s no age requirement! You have to be over 18 to be able to sponsor your account.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
     
        <div className="overlay">
            <div className="overlayDoor"></div>
            <div className="overlayContent">
            <div className="loader-logo">
                <div className="loader-circle"></div>
            </div>
            </div>
        </div>

        </Frame>
      );
    }
  }

  const useStyles = {
    root: {
      flexGrow: 1
    }
  }


  export default withRouter(withStyles(useStyles)(withSnackbar(Home)));
  //export default Home;