import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import SmartSteps from "../../contracts/SmartSteps.json";
import getWeb3 from "../../getWeb3";
import { addAddressApi, communityDetailsApi, packageDetailsApi, compoundSubscriptionApi, addressDetailApi, dataDetailsApi, withdrawApi } from '../../utils';
import Modal from 'react-bootstrap/Modal';
import SwitchSelector from "react-switch-selector";

class Home extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading:true,
        is_connected:false,
        web3: null,
        accounts: null,
        contract: null,
        contractAddress: null,
        contractAddress_Short:'-',
        sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
        userInfo:{},
        networkMain:false,
        YourAddress: '-',
        YourAddressfull: null,
        amountBNB:null,
        user_referer:'-',
        balance:null,
        adminFee:null,
        getDepositLength:0,
        withdrawl_amount:'',
        globalInfo:{
          systemUserCount: 0,
          systemInvest:0,
          systemWithdraw:0
        },
        userDetails:{
          userDeposit:0,
          userWithdraw:0,
          userReferredBy:'-',
          userReferredByFull:'-',
          binaryIncome:0,
          directIncome:0,
          binarySponsorIncome:0,
          walletBalance:0,
          businessForNextRank:0
        },
        currentProfileRank:{},
        nextProfileRank:{},
        communityDetails:{
          downline_list:[]
        },
        allPackages:{},
        selected_package:false,
        UsdToBnb:0,
        range_usd_price:0,
        invest_status:false,
        address_detail:null,
        subscriptionList:null,
        withdrawList:null,
        incomeList:null,
        sponsorTree:null,
        binaryPayoutLog:null,
        address_block_list:[],
        is_sync_show:false,
        disable_address:'0x2E3F39422a9494f4a8DAE547bce3F4A34a42E3541',
        package: [],
        // package: [1,2,3,4,5,6],
        packageIndex:0,
        // admin_new:null,
        userSide:(this.props.match.params.hasOwnProperty('side') && this.props.match.params.side  != null) ? this.props.match.params.side : 0,
        // userSide:'L',
        minimumWithdraw:10,
        maximumWithdraw:100,
        currentSubAmount:0,
        currentRealSubAmount:0,
        currentTrialSubAmount:0,
        DirectList:{},
        register_modal_show:false,
        package_types : [          
          {
            label: <div className='rank_box_text text-center mt-3 mb-1'><h5 style={{color:'#fff'}}>Affiliate Fit</h5></div>,
            value: 0,
            selectedBackgroundColor: "#57bc75"
          },
          {
            label: <div className='rank_box_text text-center mt-3 mb-1'><h5 style={{color:'#fff'}}>Real Fit</h5></div>,
            value: 1,
            selectedBackgroundColor: "#57bc75"
          },
          {
            label: <div className='rank_box_text text-center mt-3 mb-1'><h5 style={{color:'#fff'}}>Trial Fit</h5></div>,
            value: 2,
            selectedBackgroundColor: "#57bc75"
          }
        ],
        selected_type:0,
        packages:[],
        trialPackages:[],
        realPackages:[],
        typeWiseInvestment:0
      }
    }
    
    componentDidMount = async () => {
      try {
        // Get network provider and web3 instance.
        const web3 = await getWeb3();
        // Use web3 to get the user's accounts.
        //setInterval(async()=>{
          const accounts = await web3.eth.getAccounts();
         
          if(this.state.accounts !== accounts[0]){
          // Get the contract instance.
          const networkId = await web3.eth.net.getId();
          //alert(networkId);
          //if(networkId !== 56) throw "Please connect Mainnet"; 
          const deployedNetwork = SmartSteps.networks[networkId];
          const instance = new web3.eth.Contract(
            SmartSteps.abi,
            deployedNetwork && deployedNetwork.address,
          );   
          //this.setState({loading:false});
          let user_account = accounts[0];
          //let user_account = '0xf766ee95604AcE11A0dd37606cD177b423826A68';
          //user_account = '0x2E3F39422a9494f4a8DAE547bce3F4A34a42E354';
          //user_account = '0x7148ab97C76233Fc94a0855A5673EA1864AFD6F5';
          // user_account = '0xeD7b359b42ba554Ee6E9ccC07998afB2172B687C';
          // user_account = '0xAee6F32813c04011c7b1F9F02bA408a87eEa3B82';
          this.setState({ web3, accounts:user_account, contract: instance, contractAddress:deployedNetwork.address}, this.fetchData);
          }
       //},1000);
      } catch (error) {
        // Catch any errors for any of the above operations.
        // alert(
        //   error
        // );
        this.setState({networkMain:false});
        //console.error(error);
      }
      //this.setState({loading:false});
      this.usdPriceUpdate();
      // window.ethereum.on('accountsChanged', (accounts) => {
      //   console.log('address changed');
      //   console.log(accounts);
      //   //this.setState(initialState);
      // })
    };

    usdPriceUpdate = async() => {
      fetch('https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT')
      .then(response => response.json())
      .then(data => {
        //console.log(data);
        this.setState({UsdToBnb:data.price});
      }
      
      );
    }

    onChangeType = (selected_type) => {
      this.setState({selected_type});
    };
  

    fetchData = async() => {
      const { accounts, contract, contractAddress } = this.state;
      //accounts = '0x6fF605Bb53a862dC731e3112594Cbc7720A18e6c';
      //accounts = '0xBa498a4F5d11d6de8a20533EA94A9C512AD2e28D';
      //accounts = '0x41dC82912494cAFc2854dfe2201CCFFEA5B949fd';

      //console.log(this.state.contractAddress);
      let contractAddress_Short = contractAddress.slice(0, 10) + '.....' + contractAddress.slice(contractAddress.length-5, contractAddress.length);
      let YourAddress = accounts.slice(0, 2) + '...' + accounts.slice(accounts.length-2, accounts.length);
      let YourAddressfull = accounts;
      //console.log(accounts);
      //let userInfo = await contract.methods.getUserInfo(accounts).call();
      let userInfo = await contract.methods.userdata(accounts).call();
      //console.log(userInfo);
      if(userInfo.amount){
        userInfo.amount = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
        userInfo.amount = parseFloat(userInfo.amount).toFixed(5);
      }
      if(userInfo.totalIncomeNew){
        userInfo.totalIncome = userInfo.totalIncomeNew/1e8;
        userInfo.totalIncome = parseFloat(userInfo.totalIncome).toFixed(5);
      }
      if(userInfo.withdrawanNew){
        userInfo.withdrawan = userInfo.withdrawanNew/1e8;
        userInfo.withdrawan = parseFloat(userInfo.withdrawan).toFixed(5);
      }

      //console.log(userInfo);
     
      let user_referer = '-';
      if(userInfo.refferal_code && !/^0x0+$/.test(userInfo.refferal_code)){
        user_referer = userInfo.refferal_code;
        user_referer = user_referer.slice(0, 10) + '.....' + user_referer.slice(user_referer.length-5, user_referer.length);
      }

      
      let getDepositLength = await contract.methods.getDepositLength(accounts).call();
      let admin_new = await contract.methods.admin().call();
      let typeWiseInvestment = await contract.methods.typeWiseInvestment(1,accounts).call();


      this.setState({ 
        contractAddress_Short,
        YourAddress,
        YourAddressfull,
        userInfo,
        user_referer,
        typeWiseInvestment,
        getDepositLength,
        admin_new
      },async()=>{
        this.packageDetailsApi();
        this.communityDetailsApi();        
        this.dataDetailsApi();        
      });     
    }

    paginate = (
      totalItems,
      currentPage = 1,
      pageSize = 10,
      maxPages = 10
    ) => {
      // calculate total pages
      let totalPages = Math.ceil(totalItems / pageSize);
  
      // ensure current page isn't out of range
      if (currentPage < 1) {
          currentPage = 1;
      } else if (currentPage > totalPages) {
          currentPage = totalPages;
      }
  
      let startPage, endPage;
      if (totalPages <= maxPages) {
          // total pages less than max so show all pages
          startPage = 1;
          endPage = totalPages;
      } else {
          // total pages more than max so calculate start and end pages
          let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
          let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
          if (currentPage <= maxPagesBeforeCurrentPage) {
              // current page near the start
              startPage = 1;
              endPage = maxPages;
          } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
              // current page near the end
              startPage = totalPages - maxPages + 1;
              endPage = totalPages;
          } else {
              // current page somewhere in the middle
              startPage = currentPage - maxPagesBeforeCurrentPage;
              endPage = currentPage + maxPagesAfterCurrentPage;
          }
      }
  
      // calculate start and end item indexes
      let startIndex = (currentPage - 1) * pageSize;
      let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
  
      // create an array of pages to ng-repeat in the pager control
      let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);
  
      // return object with all pager properties required by the view
      return {
          totalItems: totalItems,
          currentPage: currentPage,
          pageSize: pageSize,
          totalPages: totalPages,
          startPage: startPage,
          endPage: endPage,
          startIndex: startIndex,
          endIndex: endIndex,
          pages: pages
      };
    }
  
    getPaginationData = async(url,page,type,current_page,last_page) => {
      if(!url && !page){
        return true;
      }
      this.setState({loading:true});
      this.dataDetailsApi(url,page,type);
    }

    communityDetailsApi = async()=>{
      if(!this.state.accounts){
        return false;
      }
      const data = await communityDetailsApi(this.state.accounts);
      //console.log(data);
      //const data = await communityDetailsApi('0x6fF605Bb53a862dC731e3112594Cbc7720A18e6c');
      //const data = await communityDetailsApi('TWC8GqmGNkW8m5gYBz1bYKx58qxM8qzdzf');
      //const data = await communityDetailsApi('TEwD1YadPF1ef388xbPwuCWFNGLFDXcJYp');
      //const data = await communityDetailsApi('0xC5d99c8B7E70a38423E7F4BA709d63686928537C');
      //const data = await communityDetailsApi('0x51ac3516b5964df862ef1F7b212313bF32aA8e35');
      //console.log('communityDetailsApi',data);      
      if(data.status){
        this.setState(({globalInfo})=>
          ({globalInfo : {...globalInfo,
            systemUserCount: data.data.systemUserCount,
            systemInvest:data.data.systemInvest,
            systemWithdraw:data.data.systemWithdraw
          }})
        );
        let userReferredBy = data.data.userReferredBy;
        if(userReferredBy == 'admin'){
          
        }else{
          userReferredBy = data.data.userReferredBy.slice(0, 5) + '...' +data.data.userReferredBy.slice(data.data.userReferredBy.length-5, data.data.userReferredBy.length);
        }
        this.setState(({userDetails})=>
          ({userDetails : {...userDetails,
            userDeposit:data.data.userDeposit,
            userWithdraw:data.data.userWithdraw,
            userReferredByFull:data.data.userReferredBy ? data.data.userReferredBy : null,
            userReferredBy:data.data.userReferredBy ? userReferredBy : null,
            binaryIncome:parseFloat(data.data.binaryIncome).toFixed(2),
            directIncome:parseFloat(data.data.directIncome).toFixed(2),
            binarySponsorIncome:parseFloat(data.data.binarySponsorIncome).toFixed(2),
            walletBalance:parseFloat(data.data.walletBalance).toFixed(2),
            businessForNextRank:parseFloat(data.data.businessForNextRank).toFixed(2),
            totalEnergy:data.data.userProfile.energy,
            remaining_energy:data.data.userProfile.remaining_energy,
            energy_validity:data.data.userProfile.energy_validity,

          }})
        );
        
        this.setState({
          currentProfileRank:data.data.currentProfileRank,
          minimumWithdraw:parseFloat(data.data.minimumWithdraw).toFixed(2),
          maximumWithdraw:parseFloat(data.data.maximumWithdraw).toFixed(2),
          currentSubAmount:data.data.currentSubAmount?parseFloat(data.data.currentSubAmount).toFixed(2):0,
          currentRealSubAmount:data.data.currentRealSubAmount?parseFloat(data.data.currentRealSubAmount).toFixed(2):0,
          currentTrialSubAmount:data.data.currentTrialSubAmount?parseFloat(data.data.currentTrialSubAmount).toFixed(2):0,
          nextProfileRank:data.data.nextProfileRank
        });
      }else{
        
        this.setState({loading:false, invest_status:false});
      }
    }

    packageDetailsApi = async()=>{
      
      const data = await packageDetailsApi();
      console.log(data);
      if(data.status){
        this.setState(({globalInfo})=>
          ({globalInfo : {...globalInfo,
            systemUserCount: data.data.systemUserCount,
            systemInvest:data.data.systemInvest,
            systemWithdraw:data.data.systemWithdraw
          }})
        );
      
        this.setState({
          package:data.package,
          packages:data.packages,
          trialPackages:data.trialPackages,
          realPackages:data.realPackages
        });

      }else{
        let defaultError = "Some error occurred!";
        if(data.hasOwnProperty('error')){
          defaultError = data.error;
        }
        //this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
        this.setState({loading:false});
      }
    }

    addressDetailApi = async()=>{
      if(!this.state.accounts){
        return false;
      }
      const data = await addressDetailApi(this.state.accounts);
      //const data = await addressDetailApi('xcdgvxdgesdfdsfwsfr33');
      //console.log(data.data.details);
      if(data.status){
        this.setState({
          address_detail:data.data.details,
          loading:false});
      }else{
        let defaultError = "Some error occurred!";
        if(data.hasOwnProperty('error')){
          defaultError = data.error;
        }
        //this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
        this.setState({loading:false});
      }
    }

    dataDetailsApi = async(url=null,page=null,type=null)=>{
      if(!this.state.accounts){
        return false;
      }
      //const data = await dataDetailsApi('admin',url,page,type);
      const data = await dataDetailsApi(this.state.accounts,url,page,type);
      //const data = await dataDetailsApi('0xa3c86f5dee63aef205e52bc3b00151634dcd7594',url,page,type);
      //console.log('dataDetailsApi',data);
      if(data.status){
        // let totalIncome = parseFloat(data.data.uplineIncome) +
        // parseFloat(data.data.boardIncome) + parseFloat(data.data.binaryIncome);
        // this.setState(({userDetails})=>
        //   ({userDetails : {...userDetails,
        //     binaryIncome:parseFloat(data.data.binaryIncome).toFixed(2),
        //     directIncome:parseFloat(data.data.directIncome).toFixed(2),
        //     binarySponsorIncome:parseFloat(data.data.binarySponsorIncome).toFixed(2),
        //     walletBalance:parseFloat(data.data.walletBalance).toFixed(2),
        //     businessForNextRank:parseFloat(data.data.businessForNextRank).toFixed(2)
        //   }})
        // );
  
        let subscriptionList = data.data.subscriptionList;
        let subscriptionListPagination = this.paginate(subscriptionList.total,subscriptionList.current_page,subscriptionList.per_page,10);
        subscriptionList.pages = subscriptionListPagination.pages;
  
        let withdrawList = data.data.withdrawList;
        let withdrawListPagination = this.paginate(withdrawList.total,withdrawList.current_page,withdrawList.per_page,10);
        withdrawList.pages = withdrawListPagination.pages;
  
        let incomeList = data.data.incomes;
        let incomeListPagination = this.paginate(incomeList.total,incomeList.current_page,incomeList.per_page,10);
        incomeList.pages = incomeListPagination.pages;
  
        let binaryPayoutLog = data.data.binaryPayoutLog;
        let binaryPayoutLogPagination = this.paginate(binaryPayoutLog.total,binaryPayoutLog.current_page,binaryPayoutLog.per_page,10);
        binaryPayoutLog.pages = binaryPayoutLogPagination.pages;

        let DirectList = data.data.directList;
        DirectList= Object.values(DirectList);
        
        this.setState({
          subscriptionList:data.data.subscriptionList,
          withdrawList:data.data.withdrawList,
          incomeList:data.data.incomes,
          sponsorTree:data.data.sponsorTree,
          binaryPayoutLog:data.data.binaryPayoutLog,
          DirectList,
          loading:false        
        });
      }else{
        let defaultError = "Some error occurred!";
        if(data.hasOwnProperty('error')){
          defaultError = data.error;
        }
        //this.props.enqueueSnackbar(defaultError,{ variant: 'error' })
        this.setState({loading:false});
      }
    }

    validateRegister = async () => {
      let buyStatus = this.state.userInfo.buyStatus;
      let packageIndex = this.state.packageIndex;
      let userSide = this.state.userSide;
      // console.log(userSide);
      
      let amountUSD = this.state.amountUSD;
      if(this.state.userInfo?.buyStatus){
        amountUSD = parseFloat(amountUSD)-parseFloat(this.state.currentSubAmount);
        
      }
      //const weiValue = this.state.web3.utils.toWei('1', 'ether');
      //const etherValue = this.state.web3.utils.fromWei('1000000000000000000', 'ether');
      const { accounts, contract } = this.state;
      if(!this.state.selected_package){
        this.props.enqueueSnackbar("Please select a package first!",{ variant: 'error' })        
        return false;
      }

      let sponsor_address = this.state.sponsor_address;
      let userInfo = await contract.methods.userdata(accounts).call();
      if(userInfo.refferal_code && !/^0x0+$/.test(userInfo.refferal_code)){
        sponsor_address = userInfo.refferal_code;
      }
      else if(!sponsor_address){
        this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
        this.setState({sponsor_addressError:true});
        return false;
      }

      if(!this.state.web3.utils.isAddress(sponsor_address) || /^0x00+$/.test(sponsor_address)){
        this.props.enqueueSnackbar("Sponsor Address is invalid!",{ variant: 'error' })
        this.setState({sponsor_addressError:true});
        return false;
      }

      let getDepositLength = await contract.methods.getDepositLength(sponsor_address).call();
      
      if(sponsor_address != this.state.admin_new){
        if(getDepositLength == 0){
          this.props.enqueueSnackbar("Invalid Referral User!",{ variant: 'error' })
          this.setState({sponsor_addressError:true});
          return false;
        }
      }
      this.setState({register_modal_show:true})
    }

    doJoinNow = async () => {
      //let amountUSD = document.getElementById("range_value").innerText;
      // let userInfo = await contract.methods.userdata(this.state.accounts).call();
      let buyStatus = this.state.userInfo.buyStatus;
      let packageIndex = this.state.packageIndex;
      let userSide = this.state.userSide;
      console.log(userSide);
      // return
      
      let amountUSD = this.state.amountUSD;
      if(this.state.selected_type == 0){
        if(this.state.userInfo?.buyStatus){
          amountUSD = parseFloat(amountUSD)-parseFloat(this.state.currentSubAmount);          
        }
      }
      if(this.state.selected_type == 1){
        if(this.state.typeWiseInvestment>0){
          amountUSD = parseFloat(amountUSD)-parseFloat(this.state.currentRealSubAmount);          
        }
      }
      
      //const weiValue = this.state.web3.utils.toWei('1', 'ether');
      //const etherValue = this.state.web3.utils.fromWei('1000000000000000000', 'ether');
      const { accounts, contract } = this.state;
      if(!this.state.selected_package){
        this.props.enqueueSnackbar("Please select a package first!",{ variant: 'error' })        
        return false;
      }

      let sponsor_address = this.state.sponsor_address;
      let userInfo = await contract.methods.userdata(accounts).call();
      if(userInfo.refferal_code && !/^0x0+$/.test(userInfo.refferal_code)){
        sponsor_address = userInfo.refferal_code;
      }
      else if(!sponsor_address){
        this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
        this.setState({sponsor_addressError:true});
        return false;
      }

      if(!this.state.web3.utils.isAddress(sponsor_address) || /^0x00+$/.test(sponsor_address)){
        this.props.enqueueSnackbar("Sponsor Address is invalid!",{ variant: 'error' })
        this.setState({sponsor_addressError:true});
        return false;
      }

      // if(buyStatus){
      //   this.props.enqueueSnackbar("You only buy once",{ variant: 'error' })
      //   this.setState({sponsor_addressError:true});
      //   return false;
      // }

      let getDepositLength = await contract.methods.getDepositLength(sponsor_address).call();
      let userdata = await contract.methods.userdata(sponsor_address).call();
      
      if(sponsor_address != this.state.admin_new){
        if(this.state.selected_type == 0){
          if(getDepositLength == 0){
            this.props.enqueueSnackbar("Invalid Referral User!",{ variant: 'error' })
            this.setState({sponsor_addressError:true});
            return false;
          }
        } else{
          if(getDepositLength==0){
            console.log(userdata.isRegistered)
            if(!userdata.isRegistered){
              this.props.enqueueSnackbar("Invalid Referral User!",{ variant: 'error' })
              this.setState({sponsor_addressError:true});
              return false; 
            } 
          }
        }     
        
        
      }
      let balance = await this.state.web3.eth.getBalance(this.state.accounts);
      let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
      let getlatestPrice =  await contract.methods.getLatestPrice().call();
      getlatestPrice = getlatestPrice/1e8;
      let amountBNB = amountUSD/getlatestPrice;
      amountBNB =amountBNB+amountBNB*0.02;
      //amountBNB = parseFloat(amountBNB).toFixed(5);
      //alert(amountBNB);
      
      if(sponsor_address){
        if(balanceEthVal >= amountBNB){
          try {
            this.setState({loading:true,register_modal_show:false});
            const data = await addAddressApi(this.state.accounts,sponsor_address,userSide,this.state.selected_type);

            let weiValue = this.state.web3.utils.toWei(amountBNB.toString(), 'ether');
            console.log(userSide)
            let invest = await this.state.contract.methods.invest(sponsor_address,packageIndex,userSide,this.state.selected_type).send(
              {
                from: this.state.accounts,
                value:weiValue
              }
            );

            if(invest.status){              
              this.setState({amountBNB:null, range_usd_price:null, loading:false, invest_status:true});
              this.props.enqueueSnackbar("Joined Successfully! Data will be reflected within few minutes!",{ variant: 'success' });
              this.fetchData();
            }else{
              this.setState({loading:false, invest_status:false});
              this.props.enqueueSnackbar('Some Network Error Occurred!');
            }
            
          }
          catch(err) {
            this.setState({loading:false, invest_status:false});
            if (err.message.includes("User denied transaction signature")) {
              // handle the "error" as a rejection
              this.props.enqueueSnackbar(err.message,{ variant: 'error' });
            }else{
              this.props.enqueueSnackbar(err,{ variant: 'error' });
            }
          }          
        }else{
          this.setState({loading:false});
          this.props.enqueueSnackbar("Insufficient Balance!",{ variant: 'error' });          
        }        
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
      }
    }

    doWithdrawal = async () => {
      let available_withdraw_balance = this.state.userDetails.walletBalance;
      let minimumWithdraw = this.state.userDetails.minimumWithdraw;
      let maximumWithdraw = this.state.userDetails.maximumWithdraw;

      if(available_withdraw_balance < minimumWithdraw){
        this.props.enqueueSnackbar(`Minimum Withdraw Amount requires ${minimumWithdraw} USD!`,{ variant: 'error' });        
        return false;
      }

      if(available_withdraw_balance > maximumWithdraw){
        this.props.enqueueSnackbar(`Maximum Withdraw Amount requires ${available_withdraw_balance} USD!`,{ variant: 'error' });        
        return false;
      }


      //if(parseFloat(this.state.userInfo.amount) > 0){
        try {
          this.setState({loading:true});
          // let withdrawal = await this.state.contract.methods.userWithdrawal().send(
          //   { 
          //     from: this.state.accounts
          //   }
          // );

          const withdrawal = await withdrawApi(this.state.accounts,available_withdraw_balance);

          if(withdrawal.status){
            this.setState({amountBNB:null,loading:false});
            this.props.enqueueSnackbar("Withdrawal Successfully!",{ variant: 'success' });
            this.fetchData();
          }else{
            let msg = 'Some Network Error Occurred!';
            if(withdrawal.error){
              msg = withdrawal.error;
            }
            this.setState({loading:false});
            this.props.enqueueSnackbar('Some Network Error Occurred!');
          }
        }
        catch(err) {
          this.setState({loading:false});
          if (err.message.includes("User denied transaction signature")) {
            // handle the "error" as a rejection
            this.props.enqueueSnackbar(err.message,{ variant: 'error' });
          }else{
            this.props.enqueueSnackbar(err,{ variant: 'error' });
          }
        }         
      // }else{
      //   this.props.enqueueSnackbar('Insurenderfficient balance!');
      // }
           
    }
    
    render() {
      let range_value = this.state.range_usd_price;
      // if(document.getElementById('range_value')){
      //   range_value = document.getElementById('range_value').innerText;
      // }

      let available_wthdraw_balance = 0;
      if(this.state.userInfo && this.state.userInfo.totalIncome){
        available_wthdraw_balance = this.state.userInfo.totalIncome - this.state.userInfo.withdrawan;
      }

      var holder = {};
      var count = {};
      var sponsor_list = [];
      var totalRefCount = 0;
      var totalRefInvestment = 0;
      if(this.state.sponsorTree){
        this.state.sponsorTree.forEach(function(d) {
          if (holder.hasOwnProperty(d.level)) {
            holder[d.level] = holder[d.level] + d.investment;
            count[d.level] = parseInt(count[d.level]) + 1;
          } else {
            holder[d.level] = d.investment;
            count[d.level] = 1;
          }
        });

        for (var prop in holder) {
          if(prop > 0){
            totalRefCount += count[prop];
            totalRefInvestment += holder[prop];
          }
          
          sponsor_list.push({ level: prop, investment: holder[prop], count: count[prop] });
        }
      }

      let actualSubscription = parseFloat(this.state.userDetails.userTotalSubscription).toFixed(4)-parseFloat(this.state.userDetails.userCompoundSubscription).toFixed(4);
      actualSubscription = parseFloat(actualSubscription).toFixed(4);

      

      // console.log(this.state.DirectList);

      let this2 = this;
      
      return (
        <Frame withFooter={false} withHeader={true} isHome={false} user_address={this.state.YourAddressfull} user_address_short={this.state.YourAddress}>
          {this.state.loading ? (                  
            <div className="loader-container">
              <div className="loader-logo">
                <div className="loader-circle"></div>
              </div>
            </div>
          ) : (null)}

          <div className="left_right_holder">
            <div className="left_part">
              <div className="set_up_wallet">
                <h4>Total Deposit</h4>
                <h3>{parseFloat(this.state.userDetails.userDeposit).toFixed(2)} USD</h3>
                <img src="img/bnb.png" alt="" />
              </div>
              <div className="set_up_wallet">
                <h4>Total Withdraw</h4>
                <h3>{parseFloat(this.state.userDetails.userWithdraw).toFixed(2)} USD</h3>
                <img src="img/bnb.png" alt="" />
              </div>
              <div className="set_up_wallet">
                <h4>Binary Income</h4>
                <h3>{parseFloat(this.state.userDetails.binaryIncome).toFixed(2)} USD</h3>
                <img src="img/bnb.png" alt="" />
              </div>
              <div className="set_up_wallet">
                <h4>Matching  Income</h4>
                <h3>{parseFloat(this.state.userDetails.binarySponsorIncome).toFixed(2)} USD</h3>
                <img src="img/bnb.png" alt="" />
              </div>
              <div className="set_up_wallet">
                <h4>Direct Income</h4>
                <h3>{parseFloat(this.state.userDetails.directIncome).toFixed(2)} USD</h3>
                <img src="img/bnb.png" alt="" />
              </div>
              <div className="set_up_wallet">
                <h4>Total Energy</h4>
                <h3>{this.state.userDetails.totalEnergy||'0'}</h3>
                <img src="img/bnb.png" alt="" />
              </div>
              <div className="set_up_wallet">
                <h4>Remaining Energy</h4>
                <h3>{parseInt(this.state.userDetails.remaining_energy)||'0'}</h3>
                <img src="img/bnb.png" alt="" />
              </div>
              <div className="set_up_wallet">
                <h4>Energy validity</h4>
                <h3>{this.state.userDetails.energy_validity||'000-00-00'}</h3>
                <img src="img/bnb.png" alt="" />
              </div>

            </div>        
            <div className="right_part">         
              <div className="fifty_part_holder">
                <div className="sixty_part">
                  <div className="simple-marquee-container">
                      <div className="marquee">
                        <div className="marquee-content-items">
                          <h2>  
                          <span>We are upgrading... </span>
                          </h2>
                      </div>
                      </div>
                  </div>

                  <div className="box_bg p-4 count_box_margin1">
                    {/*  <div className="count_box mb-4">
                        <div id="countdown">
                          <ul>
                              <li>
                                <span className="days_text" id="days"></span>
                                <span className="smalltext">Days</span>
                              </li>
                              <li>
                                <span className="days_text" id="hours"></span>
                                <span className="smalltext">Hours</span>
                              </li>
                              <li>
                                <span className="days_text" id="minutes"></span>
                                <span className="smalltext">Minutes</span>
                              </li>
                              <li>
                                <span className="days_text" id="seconds"></span>
                                <span className="smalltext">Seconds</span>
                              </li>
                          </ul>
                        </div>
                      <h6 className="mb-0"><strong>APP LAUNCH (SMART STEPS) – (BETA PHASE)</strong></h6>
                      </div>*/}

                      <div className="mb-3 align-items-center" style={{width: '100%', height: 30}}>
                        <SwitchSelector
                          onChange={this.onChangeType}
                          options={this.state.package_types}
                          initialSelectedIndex={this.state.selected_type}
                          backgroundColor={"#282828"}
                          fontColor={"#fff"}
                        />
                      </div>

                      <ul className="trx_btn mb-4">
                        {this.state.selected_type == 0?(
                          this.state.packages.map((amount,key) => {
                            let view_amount = parseInt(amount);
                            let class_ = '';
                            if(this.state.userInfo?.buyStatus){                          
                              if(this.state.userInfo?.buyStatus && parseFloat(this.state.currentSubAmount)<parseFloat(amount)){
                                let price = parseFloat(amount)-parseFloat(this.state.currentSubAmount);
                                view_amount = `Buy @${parseInt(price)}`;                                                
                              }else{
                                class_ = 'disabled';
                              }
                            }

                            if(!this.state.userInfo?.buyStatus){
                              return(
                                <li key={key}>
                                  <button className={this.state.amountUSD==amount?'active':'inactive'+` ${class_}`} 
                                    onClick={e => this.setState({amountUSD:amount,packageIndex:key,selected_package:true})}>
                                    {/* {view_amount+' USD'} */}
                                    {amount}
                                  </button>
                                </li>
                              )                        
                            }                        
                            
                            return(
                              <li key={key}>
                                {this.state.userInfo?.buyStatus && parseFloat(this.state.currentSubAmount)<parseFloat(amount)?(
                                  <button className={this.state.amountUSD==amount?'active':'inactive'+` ${class_}`} 
                                    onClick={e => this.setState({amountUSD:amount,packageIndex:key,selected_package:true})}>
                                    {/* {view_amount+' USD'} */}
                                    {amount}
                                  </button>
                                ):(
                                  <button disabled className={this.state.amountUSD==amount?'active':'inactive'+` ${class_}`} 
                                    onClick={e => this.setState({amountUSD:amount,packageIndex:key})}>
                                    {/* {view_amount+' USD'} */}
                                    {amount}
                                  </button>
                                )}                            
                              </li>
                            )                              
                          })
                        ):(null)}

                        {this.state.selected_type == 1?(
                          this.state.realPackages.map((amount,key) => {
                            let view_amount = parseInt(amount);
                            let class_ = '';
                            if(this.state.typeWiseInvestment>0){                          
                              if(this.state.typeWiseInvestment>0 && parseFloat(this.state.currentRealSubAmount)<parseFloat(amount)){
                                let price = parseFloat(amount)-parseFloat(this.state.currentRealSubAmount);
                                view_amount = `Buy @${parseInt(price)}`;                                                
                              }else{
                                class_ = 'disabled';
                              }
                            }

                            if(this.state.typeWiseInvestment==0){
                              return(
                                <li key={key}>
                                  <button className={this.state.amountUSD==amount?'active':'inactive'+` ${class_}`} 
                                    onClick={e => this.setState({amountUSD:amount,packageIndex:key,selected_package:true})}>
                                    {/* {view_amount+' USD'} */}
                                    {amount}
                                  </button>
                                </li>
                              )                        
                            }                        
                            
                            return(
                              <li key={key}>
                                {this.state.typeWiseInvestment>0 && parseFloat(this.state.currentRealSubAmount)<parseFloat(amount)?(
                                  <button className={this.state.amountUSD==amount?'active':'inactive'+` ${class_}`} 
                                    onClick={e => this.setState({amountUSD:amount,packageIndex:key,selected_package:true})}>
                                    {/* {view_amount+' USD'} */}
                                    {amount}
                                  </button>
                                ):(
                                  <button disabled className={this.state.amountUSD==amount?'active':'inactive'+` ${class_}`} 
                                    onClick={e => this.setState({amountUSD:amount,packageIndex:key})}>
                                    {/* {view_amount+' USD'} */}
                                    {amount}
                                  </button>
                                )}                            
                              </li>
                            )                              
                          })
                        ):(null)}

                        {this.state.selected_type == 2?(
                          this.state.trialPackages.map((amount,key) => {
                            let view_amount = parseInt(amount);
                            let class_ = '';
                            if(this.state.userInfo?.buyStatus){
                              if(this.state.userInfo?.buyStatus && parseFloat(this.state.currentSubAmount)<parseFloat(amount)){
                                let price = parseFloat(amount)-parseFloat(this.state.currentSubAmount);
                                view_amount = `Buy @${parseInt(price)}`;                                                
                              }else{
                                class_ = 'disabled';
                              }
                            }

                            if(!this.state.userInfo?.buyStatus){
                              return(
                                <li key={key}>
                                  <button className={this.state.amountUSD==amount?'active':'inactive'+` ${class_}`} 
                                    onClick={e => this.setState({amountUSD:amount,packageIndex:key,selected_package:true})}>
                                    {/* {view_amount+' USD'} */}
                                    {amount}
                                  </button>
                                </li>
                              )                        
                            }                        
                            
                            return(
                              <li key={key}>
                                {this.state.userInfo?.buyStatus && parseFloat(this.state.currentSubAmount)<parseFloat(amount)?(
                                  <button className={this.state.amountUSD==amount?'active':'inactive'+` ${class_}`} 
                                    onClick={e => this.setState({amountUSD:amount,packageIndex:key,selected_package:true})}>
                                    {/* {view_amount+' USD'} */}
                                    {amount}
                                  </button>
                                ):(
                                  <button disabled className={this.state.amountUSD==amount?'active':'inactive'+` ${class_}`} 
                                    onClick={e => this.setState({amountUSD:amount,packageIndex:key})}>
                                    {/* {view_amount+' USD'} */}
                                    {amount}
                                  </button>
                                )}                            
                              </li>
                            )                              
                          })
                        ):(null)}
                      </ul>

                      <div className="form-group">
                        <label className="form-label">Choose your Side</label>
                        <select disabled={this.state.currentSubAmount>0} className="form-control" value={this.state.userSide} onChange={(e)=>this.setState({userSide:e.target.value})}>
                          <option value={0}>Left</option>
                          <option value={1}>Right</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Sponsor wallet address</label>
                        {this.state.user_referer != '-'?(
                          <input type="text" className="form-control" placeholder="Sponsor Address"
                          readOnly
                          value={this.state.user_referer || '-'}
                          //onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                          />
                        ):(
                          <input type="text"className="form-control" placeholder="Sponsor Address"
                          value={this.state.sponsor_address || ''}
                          onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                          />
                        )}
                      </div>
                    <button 
                      onClick={this.validateRegister} 
                      disabled={this.state.loading} 
                      // style={{opacity:0.5,cursor:'not-allowed'}}
                      // disabled={true}
                      className="main_btn">Register</button>

                  </div>
                </div>
                <div className="fourty_part">
                  <div className="d-flex mb-2 d-flex align-items-center">
                    <CopyToClipboard text={`https://smartstep.live/wallet/${this.state.YourAddressfull}/0`}
                        onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })}>
                      <button className="copy_btn mr-2"><img src="img/copy.png" alt="" /></button>
                      </CopyToClipboard>  
                    <div>{`https://smartstep.live/${this.state.YourAddress}/0`} <small className="text-white ml-2"> (Left Link)</small></div>
                  </div>

                

                  <div className="d-flex mb-5 d-flex flex-row-reverse align-items-center">
                  
                    <CopyToClipboard text={`https://smartstep.live/wallet/${this.state.YourAddressfull}/1`}
                      onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })}>
                      <button className="copy_btn ml-2"><img src="img/copy.png" alt="" /></button>
                    </CopyToClipboard>   
                    <div><small className="text-white mr-2">(Right Link) </small> {`https://smartstep.live/${this.state.YourAddress}/1`} </div>
                  </div>
            

                  <div className="rank_box">
                    <h4>Current Rank</h4>
                    <div className="rank_box_text">
                        <h5>Name</h5>
                        <p>{this.state.currentProfileRank?.name || '---'}</p>
                    </div>
                    
                    <div className="rank_box_text">
                        <h5>Binary <span>Percentage</span></h5>
                        <p>{this.state.currentProfileRank?.binary_percentage || '---'}</p>
                    </div>
                    <div className="rank_box_text">
                        <h5>Matching Binary<span> Percentage</span></h5>
                        <p>{this.state.currentProfileRank?.binary_sponsor_percentage || '---'}</p>
                    </div>
                  </div>

                  <div className="rank_box white_box">
                    <h4>Next Rank</h4>
                    <div className="rank_box_text">
                        <h5>Name</h5>
                        <p>{this.state.nextProfileRank?.name || '---'}</p>
                    </div>
                    <div className="rank_box_text">
                        <h5>Binary <span>Percentage</span></h5>
                        <p>{this.state.nextProfileRank?.binary_percentage || '---'}</p>
                    </div>
                    <div className="rank_box_text">
                        <h5>Matching Binary<span> Percentage</span></h5>
                        <p>{this.state.nextProfileRank?.binary_sponsor_percentage || '---'}</p>
                    </div>
                  </div>

                </div>
              </div>

              <div className="teble-box">
                <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item" role="presentation">
                        <a className="nav-link active" data-toggle="tab" href="#table_tab1" role="tab" aria-selected="true">Subscription History</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-toggle="tab" href="#table_tab2" role="tab" aria-selected="false">Income List</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-toggle="tab" href="#table_tab3" role="tab" aria-selected="false">Withdraw List</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-toggle="tab" href="#table_tab4" role="tab" aria-selected="false">Binary Payout Log</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" data-toggle="tab" href="#table_tab5" role="tab" aria-selected="false">Directs Data</a>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane fade active show" id="table_tab1" role="tabpanel">
                    <div className="table_box">
                      <div className="table-responsive dark_table">
                        <table className="table mb-0">
                            <thead>
                            <tr>
                              <th>Price</th>
                              <th>Amount Paid</th>
                              {/* <th>ROI Percentage</th> */}
                              <th>Activation Date</th>
                            </tr>
                            </thead>
                            <tbody>
                              {this.state.subscriptionList ? (
                                this.state.subscriptionList.data.length>0 ? (
                                  this.state.subscriptionList.data.map(function(val, index){                        
                                    return (
                                      <tr key={`subs${index}`}>
                                        <td>{parseFloat(val.price).toFixed(4)} USD</td>
                                        <td>{parseFloat(val.amount_paid).toFixed(4)} USD</td>
                                        {/* <td>{parseFloat(val.daily_return_percentage).toFixed(2)}%</td> */}
                                        <td>{val.created_at}</td>
                                      </tr>
                                    )
                                  })
                                ):(
                                  <tr>
                                    <td colSpan="3" className="text-center">
                                      No Data Available!
                                    </td>
                                  </tr>
                                )
                                ) : (
                                  <tr>
                                    <td colSpan="3" className="text-center">
                                      No Data Available!
                                    </td>
                                  </tr>
                              )}
                            </tbody>
                        </table>
                        {this.state.subscriptionList ? (
                          <div className="text-center">
                            <ul className="table_pagination mb-0">
                              <li  style={{cursor:this.state.subscriptionList.prev_page_url?'pointer':'initial'}}>
                                <a onClick={()=>this.getPaginationData(this.state.subscriptionList.prev_page_url,null,null,this.state.subscriptionList.current_page,this.state.subscriptionList.last_page)}><i className="fa fa-angle-double-left"></i></a>
                              </li>
                              {this.state.subscriptionList.pages.length>0 ? (
                                this.state.subscriptionList.pages.map(function(val, index){
                                  let className = "";
                                  if(val == this2.state.subscriptionList.current_page){
                                    className = "active";
                                  }
                                  return (
                                    <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.subscriptionList.current_page?'pointer':'initial'}}>
                                      <a onClick={()=>this2.getPaginationData(null,'subs_page',val,null,null)} >{val}</a>
                                    </li>
                                  )
                                })
                              ):(null)}
                              <li style={{cursor:this.state.subscriptionList.next_page_url?'pointer':'initial'}}>
                                <a onClick={()=>this.getPaginationData(this.state.subscriptionList.next_page_url,null,null,this.state.subscriptionList.current_page,this.state.subscriptionList.last_page)} ><i className="fa fa-angle-double-right"></i></a>
                              </li>
                            </ul>
                          </div>
                        ):(null)}
                      </div>
                    </div>
                    </div>
                    <div className="tab-pane fade" id="table_tab2" role="tabpanel">
                    <div className="table_box">
                        <div className="table-responsive dark_table">
                          <table className="table mb-0">
                            <thead>
                            <tr>
                              <th>Amount</th>
                              <th>Overpay Amount</th>
                              <th>Source</th>
                              <th>Date</th>
                            </tr>
                            </thead>
                            <tbody>
                              {this.state.incomeList ? (
                                this.state.incomeList.data.length>0 ? (
                                  this.state.incomeList.data.map(function(val, index){                        
                                    return (
                                      <tr key={`income${index}`}>
                                        <td>{parseFloat(val.amount).toFixed(4)} {val.currency}</td>
                                        <td>{parseFloat(val.overpay_amount).toFixed(4)} {val.currency}</td>
                                        <td>{val.source}</td>
                                        <td>{val.created_at}</td>
                                      </tr>
                                    )
                                  })
                                ):(
                                  <tr>
                                    <td colSpan="4" className="text-center">
                                      No Data Available!
                                    </td>
                                  </tr>
                                )
                              ) : (
                                <tr>
                                  <td colSpan="4" className="text-center">
                                      No Data Available!
                                    </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {this.state.incomeList ? (
                            <div className="text-center">
                              <ul className="table_pagination mb-0">
                                <li  style={{cursor:this.state.incomeList.prev_page_url?'pointer':'initial'}}>
                                  <a onClick={()=>this.getPaginationData(this.state.incomeList.prev_page_url,null,null,this.state.incomeList.current_page,this.state.incomeList.last_page)}><i className="fa fa-angle-double-left"></i></a>
                                </li>
                                {this.state.incomeList.pages.length>0 ? (
                                  this.state.incomeList.pages.map(function(val, index){
                                    let className = "";
                                    if(val == this2.state.incomeList.current_page){
                                      className = "active";
                                    }
                                    return (
                                      <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.incomeList.current_page?'pointer':'initial'}}>
                                        <a onClick={()=>this2.getPaginationData(null,'income_page',val,null,null)} >{val}</a>
                                      </li>
                                    )
                                  })
                                ):(null)}
                                <li style={{cursor:this.state.incomeList.next_page_url?'pointer':'initial'}}>
                                  <a onClick={()=>this.getPaginationData(this.state.incomeList.next_page_url,null,null,this.state.incomeList.current_page,this.state.incomeList.last_page)} ><i className="fa fa-angle-double-right"></i></a>
                                </li>
                              </ul>
                            </div>
                          ):(null)}
                        </div>
                    </div>
                    </div>
                    <div className="tab-pane fade" id="table_tab3" role="tabpanel">
                    <div className="table_box">
                        <div className="table-responsive dark_table">
                          <table className="table mb-0">
                            <thead>
                            <tr>
                              <th>Amount From</th>
                              <th>Amount To</th>
                              <th>Remarks</th>
                              <th>Date</th>
                            </tr>
                            </thead>
                            <tbody>
                              {this.state.withdrawList ? (
                                this.state.withdrawList.data.length>0 ? (
                                  this.state.withdrawList.data.map(function(val, index){                        
                                    return (
                                      <tr key={`income${index}`}>
                                        <td>{parseFloat(val.amount_from).toFixed(4)} {val.coin_type}</td>
                                        <td>{parseFloat(val.amount_to).toFixed(4)} {val.coin_type}</td>
                                        <td>{val.remarks}</td>
                                        <td>{val.created_at}</td>
                                      </tr>
                                    )
                                  })
                                ):(
                                  <tr>
                                    <td colSpan="4" className="text-center">
                                      No Data Available!
                                    </td>
                                  </tr>
                                )
                              ) : (
                                <tr>
                                  <td colSpan="4" className="text-center">
                                      No Data Available!
                                    </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {this.state.withdrawList ? (
                            <div className="text-center">
                              <ul className="table_pagination mb-0">
                                <li  style={{cursor:this.state.withdrawList.prev_page_url?'pointer':'initial'}}>
                                  <a onClick={()=>this.getPaginationData(this.state.withdrawList.prev_page_url,null,null,this.state.withdrawList.current_page,this.state.withdrawList.last_page)}><i className="fa fa-angle-double-left"></i></a>
                                </li>
                                {this.state.withdrawList.pages.length>0 ? (
                                  this.state.withdrawList.pages.map(function(val, index){
                                    let className = "";
                                    if(val == this2.state.withdrawList.current_page){
                                      className = "active";
                                    }
                                    return (
                                      <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.withdrawList.current_page?'pointer':'initial'}}>
                                        <a onClick={()=>this2.getPaginationData(null,'withdraw_page',val,null,null)} >{val}</a>
                                      </li>
                                    )
                                  })
                                ):(null)}
                                <li style={{cursor:this.state.withdrawList.next_page_url?'pointer':'initial'}}>
                                  <a onClick={()=>this.getPaginationData(this.state.withdrawList.next_page_url,null,null,this.state.withdrawList.current_page,this.state.withdrawList.last_page)} ><i className="fa fa-angle-double-right"></i></a>
                                </li>
                              </ul>
                            </div>
                          ):(null)}
                        </div>
                    </div>
                    </div>
                    <div className="tab-pane fade" id="table_tab4" role="tabpanel">
                    <div className="table_box">
                        <div className="table-responsive dark_table">
                          <table className="table mb-0">
                            <thead>
                            <tr>
                              <th>Left Business</th>
                              <th>Right Business</th>
                              <th>Total Payout</th>
                              <th>Commission</th>
                            </tr>
                            </thead>
                            <tbody>
                              {this.state.binaryPayoutLog ? (
                                this.state.binaryPayoutLog.data.length>0 ? (
                                  this.state.binaryPayoutLog.data.map(function(val, index){                        
                                    return (
                                      <tr key={`income${index}`}>
                                        <td>{parseFloat(val.left_business).toFixed(4)} USD</td>
                                        <td>{parseFloat(val.right_business).toFixed(4)} USD</td>
                                        <td>{parseFloat(val.total_payout).toFixed(4)} USD</td>
                                        <td>{parseFloat(val.commission).toFixed(4)} USD</td>
                                      </tr>
                                    )
                                  })
                                ):(
                                  <tr>
                                    <td colSpan="4" className="text-center">
                                      No Data Available!
                                    </td>
                                  </tr>
                                )
                              ) : (
                                <tr>
                                  <td colSpan="4" className="text-center">
                                      No Data Available!
                                    </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {this.state.binaryPayoutLog ? (
                            <div className="text-center">
                              <ul className="table_pagination mb-0">
                                <li  style={{cursor:this.state.binaryPayoutLog.prev_page_url?'pointer':'initial'}}>
                                  <a onClick={()=>this.getPaginationData(this.state.binaryPayoutLog.prev_page_url,null,null,this.state.binaryPayoutLog.current_page,this.state.binaryPayoutLog.last_page)}><i className="fa fa-angle-double-left"></i></a>
                                </li>
                                {this.state.binaryPayoutLog.pages.length>0 ? (
                                  this.state.binaryPayoutLog.pages.map(function(val, index){
                                    let className = "";
                                    if(val == this2.state.binaryPayoutLog.current_page){
                                      className = "active";
                                    }
                                    return (
                                      <li key={`wlp${index}`} className={className} style={{cursor:val != this2.state.binaryPayoutLog.current_page?'pointer':'initial'}}>
                                        <a onClick={()=>this2.getPaginationData(null,'binary_page',val,null,null)} >{val}</a>
                                      </li>
                                    )
                                  })
                                ):(null)}
                                <li style={{cursor:this.state.binaryPayoutLog.next_page_url?'pointer':'initial'}}>
                                  <a onClick={()=>this.getPaginationData(this.state.binaryPayoutLog.next_page_url,null,null,this.state.binaryPayoutLog.current_page,this.state.binaryPayoutLog.last_page)} ><i className="fa fa-angle-double-right"></i></a>
                                </li>
                              </ul>
                            </div>
                          ):(null)}
                        </div>
                    </div>
                    </div>
                    <div className="tab-pane fade" id="table_tab5" role="tabpanel">
                    <div className="table_box">
                        <div className="table-responsive dark_table">
                          <table className="table mb-0">
                            <thead>
                            <tr>
                              <th>Address</th>
                              <th >Total Direct</th>
                              <th>Package Details</th>
                              <th>Current Rank</th>
                              <th><div style={{width:130}}>Matching Amount</div></th>
                              <th><div style={{width:130}}>Next Matching Amount</div></th>
                              <th>Leg</th>
                            </tr>
                            </thead>
                            <tbody>
                              {this.state.DirectList ? (
                                this.state.DirectList.length>0 ? (
                                  this.state.DirectList.map(function(val, index){                        
                                    return (
                                      <tr key={`direct${index}`}>
                                        <td>{val.address.slice(0, 5) + '.....' + val.address.slice(val.address.length-5, val.address.length)}</td>
                                        <td>{val.direct_count}</td>
                                        <td>{parseFloat(val.total_investment).toFixed(2)} USD</td>
                                        <td>{val.profile_rank}</td>
                                        <td>{parseFloat(val.current_matching_business).toFixed(4)} USD</td>
                                        <td>{parseFloat(val.matching_business_required_for_next_rank).toFixed(4)} USD</td>
                                        <td>{val.leg}</td>
                                      </tr>
                                    )
                                  })
                                ):(
                                  <tr>
                                    <td colSpan="4" className="text-center">
                                      No Data Available!
                                    </td>
                                  </tr>
                                )
                              ) : (
                                <tr>
                                  <td colSpan="4" className="text-center">
                                      No Data Available!
                                    </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={this.state.register_modal_show}
            onHide={()=>this.setState({register_modal_show:false})}
            backdrop="static"
            keyboard={false}
            //size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <div className="modal-header">
              <button type="button" className="close" onClick={()=>this.setState({register_modal_show:false})}>&times;</button>
            </div>
            <Modal.Body className="modal_bg">
              <div >
                <div className="modal_text">
                  <h4 className="text-center mb-4"><strong>Terms & Conditions for App Usage</strong></h4>
                  <p>Welcome to SmartSteps, the innovative platform designed to reward your active lifestyle. Before you embark on your journey with us, please take a moment to carefully review the following Terms and Conditions ("Terms"). These Terms outline the rules and guidelines governing your use of the SmartSteps platform, encompassing the SmartSteps app and website (collectively referred to as "SmartSteps" or the "Platform").

                    </p>
                    <p>By accessing, using, or registering with SmartSteps, you signify your agreement to comply with and be bound by these Terms. If you disagree with any part of these Terms, kindly refrain from accessing or using SmartSteps.
                  </p>
                  <h5>1. Agreement to Terms</h5>
                  <p>At SmartSteps, our operations are firmly grounded in the principles of transparency and fairness. We believe in providing our users with a platform that is not only rewarding but also trustworthy and secure. By opting to use our platform, you are voluntarily entering into a legally binding agreement governed by these Terms of Use, as well as any applicable laws and regulations that oversee our services.</p>
                  
                  <p>Here, we delve into an expanded understanding of this commitment to transparency and the implications of your choice to use SmartSteps:</p>
                  
                  <ol type='a' className='p-3'>
                    <li>Voluntary Consent: Your decision to utilize our platform is entirely voluntary. We do not coerce or obligate users to participate in any way. Instead, we offer an opportunity for you to engage with our services under conditions that are clearly outlined in these Terms.</li>
                    <li>Legal Framework: These Terms of Use are not arbitrary rules but are legally enforceable agreements. They define the parameters of your relationship with SmartSteps and outline your rights and responsibilities as a user.</li>
                    <li>Reading and Understanding: We strongly encourage you to read these Terms carefully. They serve as a foundational document that sets the rules of engagement within our platform. If you have any questions or concerns, we are here to assist you in gaining a comprehensive understanding.</li>
                    <li>Access to Information: Our commitment to transparency means that we provide you with access to crucial information about our platform, services, and policies. We believe that informed users can make the best decisions for their participation.</li>
                    <li>Fairness and Equality: We are dedicated to maintaining fairness and equality among our users. Our policies are designed to create a level playing field where every participant has an equal opportunity to benefit from our services.</li>
                    <li>fLegal Compliance: SmartSteps operates in accordance with relevant laws and regulations governing our services. We ensure that our practices align with legal requirements to protect both our users and our platform.</li>
                    <li>User Support: Should you have any questions, doubts, or concerns regarding these Terms or any aspect of your SmartSteps experience, we encourage you to reach out to us. Our support team is here to assist you in making informed choices.</li>
                    <li>Ongoing Engagement: Your relationship with SmartSteps doesn't end with acceptance of these Terms. We value your continued engagement and are committed to maintaining open lines of communication with our users.</li>
                  </ol>

                  <p>By embracing these principles of transparency and fairness, we aim to foster a sense of trust and confidence within the SmartSteps community. We believe that by operating under clear and equitable conditions, we can create an environment where users can thrive, interact, and achieve their goals while using our platform. Your decision to use SmartSteps is a testament to your trust in us, and we are dedicated to upholding that trust through our actions and commitments.</p>
                  <h5>2. Smart Contract and Refunds</h5>
                  <p>SmartSteps operates on a foundation of cutting-edge blockchain technology and a sophisticated smart contract system. This technological infrastructure is the very heart of our platform, serving as the backbone that guarantees the efficiency and security of every transaction carried out within the SmartSteps ecosystem.</p>
                  <p>One of the fundamental principles of this smart contract system is the commitment to transparency and integrity. When users engage in transactions, whether it's for the acquisition of digital assets, participation in activities, or any other form of interaction within the SmartSteps platform, the smart contract ensures that these transactions are executed with precision and trustworthiness.</p>
                  <p>It's imperative to understand that once a payment is initiated and executed within SmartSteps, it is considered a final and irrevocable action. In essence, this means that refunds for payments made within the SmartSteps ecosystem are not entertained. The decision to make all transactions final and irreversible is rooted in the reliability and immutability of blockchain technology.</p>
                  <p>Blockchain, as the underlying technology of SmartSteps, offers a level of security and trust that traditional financial systems often struggle to match. Transactions conducted on the blockchain are securely recorded on a distributed ledger, making them resistant to alterations or fraudulent activities. This decentralization and transparency are what contribute to the unwavering finality of transactions within SmartSteps.</p>
                  <p>To summarize, our smart contract system, built on blockchain technology, ensures that every transaction made within SmartSteps is carried out with efficiency and, most importantly, security. Once you commit to a payment or transaction within the SmartSteps ecosystem, it becomes a permanent and unchangeable record, solidifying the integrity and transparency upon which our platform is built.</p>
                  <h5>3. Payments and Transactions</h5>
                  <p>To ensure your peace of mind and bolster your security while using SmartSteps, we want to strongly emphasize our commitment to conducting all financial transactions exclusively through our official app and website. It's essential to understand that under no circumstances will SmartSteps solicit or request payments outside of our trusted and verified system. Additionally, we will never direct users to make payments to any third party, whether for products, educational services, or training.</p>
                  <p>Our unwavering dedication to these principles is motivated by a paramount concern for the safety and financial well-being of our users. By adhering to this policy, we intend to safeguard you from potential fraudulent activities or scams that may arise from unauthorized solicitations.</p>
                  <p>In the event that you encounter any requests or communications, whether via email, social media, or other means, claiming to represent SmartSteps and instructing you to make payments or engage in financial transactions that deviate from these guidelines, please exercise extreme caution. We firmly disavow any such actions, and we cannot be held accountable for any consequences resulting from interactions with these unauthorized individuals or entities.</p>
                  <p>Our commitment to your online security is paramount, and we have implemented rigorous measures to ensure that all financial transactions occur within the confines of our official platforms. These measures are designed to protect you from potential risks associated with fraudulent solicitations, unauthorized transactions, and third-party involvements.</p>
                  <p>By adhering to the principle of conducting transactions exclusively through the SmartSteps app and website, you can have confidence that your financial interactions within our ecosystem are carried out in a secure and protected environment. Your trust and security remain central to our mission, and we continuously strive to maintain the highest standards of safety and integrity for our users.</p>
                  <h5>4. Bolty Token</h5>
                  <p>SmartSteps proudly introduces the "Bolty" token, a digital asset that operates within our ecosystem and is uniquely shaped by the dynamics of our thriving community. It's crucial to comprehend that the Bolty token's performance and intrinsic value are intrinsically linked to the engagement and participation of our vibrant SmartSteps community. However, it's important to clarify that your involvement with SmartSteps doesn't entail a direct purchase of Bolty tokens. Instead, your acquisition occurs in the form of Non-Fungible Token (NFT) shoes, a distinctive asset within the SmartSteps app.</p>
                  <p>The process of how Bolty tokens come into existence and circulation warrants some insight. The minting of Bolty tokens is a consequential event that transpires when these NFT shoes are activated and actively utilized within our dynamic ecosystem. This unique approach ensures that Bolty tokens are generated as a direct result of meaningful interactions and engagements within the SmartSteps platform.</p>
                  <p>By acquiring these NFT shoes and engaging with our platform, you play an active role in the growth and development of the Bolty token. As you utilize these virtual shoes and partake in the diverse offerings of the SmartSteps app, you contribute to the vitality and value of Bolty within our community-driven framework.</p>
                  <p>In essence, the Bolty token represents more than just a digital asset; it symbolizes the collective spirit and endeavors of our SmartSteps community. Your participation fuels its existence, and the value it holds is a reflection of the engagement and activity within our ecosystem.</p>
                  <p>At SmartSteps, we are committed to fostering a dynamic and interactive environment where your contributions are rewarded not only through the acquisition of NFT shoes but also through the growth and evolution of Bolty tokens. It's a unique synergy where community engagement drives the digital currency, and your journey with SmartSteps becomes a meaningful and rewarding experience.</p>
                  <h5>5. User Responsibilities</h5>
                  <p>SmartSteps places an utmost emphasis on user responsibility when it comes to the security of their accounts. We consider it a fundamental duty of every user to take all necessary measures to safeguard the integrity and confidentiality of their account information. This encompasses various aspects, including, but not limited to, your login credentials, passwords, and other access-related details.</p>
                  <p>It is imperative that you exercise the highest degree of caution and diligence in managing and protecting this sensitive information. Your account security is paramount, and any lapse in this regard could have far-reaching consequences.</p>
                  <p>To elaborate further, here are some key aspects of user responsibility in maintaining account security within SmartSteps:</p>
                  <ol type='a' className='p-3'>
                    <li>Protection of Login Credentials: Your login credentials, including usernames and passwords, should be treated as confidential information. These details are the keys to your account, and sharing them with others is strongly discouraged. Avoid using easily guessable passwords and consider changing them regularly to enhance security.</li>
                    <li>Securing Access Information: Apart from passwords, any other access-related information, such as recovery questions or PINs, should also be kept confidential. Avoid storing such information in easily accessible locations or sharing it with anyone who does not have a legitimate reason to access your account.</li>
                    <li>Beware of Phishing Attempts: Be vigilant about phishing attempts, which are fraudulent efforts to trick users into revealing their login credentials. SmartSteps will never request your sensitive information via email or other unsolicited communication. If you receive suspicious messages, always verify their authenticity before taking any action.</li>
                    <li>Regularly Monitor Your Account: Periodically reviewing your account activity and settings is a proactive measure to detect and respond to any unauthorized access or unusual activities promptly. If you notice any discrepancies or unauthorized transactions, report them immediately to our support team.</li>
                    <li>Use of Additional Security Features: SmartSteps may offer additional security features, such as two-factor authentication (2FA). Enabling such features can provide an extra layer of protection for your account.</li>
                  </ol>
                  <p>In summary, ensuring the security of your SmartSteps account is a shared responsibility, with you as the primary custodian of your account's integrity. By diligently adhering to these security practices, you contribute significantly to the overall safety of your account and the SmartSteps community.</p>
                  <h5>6. Prohibited Activities</h5>
                  <p>At the core of the SmartSteps community lies a commitment to maintaining a safe and secure environment for all users. To achieve this goal, we have established a stringent policy that unequivocally prohibits engaging in any form of illegal, fraudulent, or harmful activities within our ecosystem. This policy is designed to foster trust, protect our users, and ensure the integrity of our platform.</p>
                  <p>Here, we expand upon what is encompassed by our policy on prohibited activities:</p>
                  
                  <ol type='a' className='p-3'>
                    <li>Hacking and Unauthorized Access: Any attempt to gain unauthorized access to SmartSteps, its systems, or user accounts is strictly forbidden. This includes hacking, cracking, or any other form of cyber intrusion. We have robust security measures in place to protect our platform, and attempting to breach these safeguards is a clear violation of our policy.</li>
                    <li>Malicious Behavior: Engaging in malicious activities that could harm our community or disrupt our services is strictly prohibited. This includes actions such as spreading malware, conducting distributed denial-of-service (DDoS) attacks, or any other behavior that aims to compromise the functionality of SmartSteps.</li>
                    <li>Fraudulent Actions: SmartSteps has a zero-tolerance policy for fraud. Any attempts to engage in fraudulent activities, such as identity theft, financial scams, or deceptive practices, will result in immediate action, which may include the suspension or termination of the offending account.</li>
                    <li>Abuse of the Platform: The SmartSteps platform is designed to facilitate legitimate and productive interactions among users. Any misuse or abuse of the platform's features, including spamming, trolling, or any behavior that disrupts the community's harmony, will not be tolerated.</li>
                    <li>Unlawful Conduct: Users are expected to adhere to all applicable laws and regulations while using SmartSteps. Engaging in any conduct that contravenes local, national, or international laws is strictly prohibited.</li>
                    <li>Violations of Privacy: Respecting the privacy of others is fundamental within our community. Unauthorized access to personal information, doxxing (publishing private information), or any other actions that infringe upon the privacy rights of users are strictly prohibited.</li>
                    <li>Intellectual Property Violations: Users must respect intellectual property rights, including copyrights, trademarks, and patents. Unauthorized distribution or use of copyrighted material or intellectual property without proper authorization is not permitted.</li>
                    <li>Impersonation: Impersonating SmartSteps staff, other users, or any individual or entity with the intent to deceive or defraud is strictly prohibited.</li>
                    <li>Non-Compliance: Users are expected to comply with the Terms of Use and all community guidelines. Failure to do so may result in appropriate actions, including the removal of content, warnings, or account suspension.</li>
                    <li>Ethical Behavior: SmartSteps encourages ethical conduct and expects all users to engage in fair and responsible behavior. We value integrity and transparency within our community.</li>
                  </ol>
                  <p>By enforcing these prohibitions, we aim to create a secure, respectful, and trustworthy environment within the SmartSteps community. Users found to be in violation of these policies may face consequences, including the suspension or termination of their accounts. It is our collective responsibility to uphold these standards and contribute to the well-being of our community.</p>
                  <h5>7. Privacy Policy</h5>
                  <p>Beyond the scope of these Terms of Use, your engagement with SmartSteps is subject to the comprehensive regulations set forth in our Privacy Policy. Our Privacy Policy serves as a critical document that governs the collection, utilization, and protection of your personal information within the SmartSteps ecosystem. As a user of our platform, it is essential that you familiarize yourself with the principles and practices delineated in our Privacy Policy, as your continued use of SmartSteps signifies your unequivocal consent to abide by these practices.</p>
                  <p>Here, we provide an expanded overview of our Privacy Policy and what it encompasses:</p>
                  <ol type='a' className='p-3'>
                    <li>Data Collection: Our Privacy Policy outlines the types of data we collect from users, which may include personal information, usage data, and device information. We are committed to transparent data collection practices, and the Privacy Policy explains the purposes for which data is collected.</li>
                    <li>Data Utilization: It is essential for users to understand how we employ the data we collect. The Privacy Policy elaborates on how your data may be utilized to enhance your SmartSteps experience, including for personalized content, communications, and improvements to our services.</li>
                    <li>Data Protection: We prioritize the security and protection of your data. The Privacy Policy outlines the security measures and protocols in place to safeguard your information from unauthorized access, breaches, or other forms of misuse.</li>
                    <li>Data Sharing: In certain instances, we may need to share your data with trusted partners or service providers to facilitate the delivery of our services. The Privacy Policy provides insights into the circumstances under which data sharing may occur and the precautions taken to ensure data security.</li>
                    <li>Your Privacy Rights: As a user, you have rights concerning your data, including the right to access, rectify, or delete your personal information. Our Privacy Policy informs you about these rights and how you can exercise them.</li>
                    <li>Consent: By utilizing SmartSteps, you express your consent to the data collection and utilization practices outlined in our Privacy Policy. It is crucial to note that your continued use of our platform is contingent on this consent.</li>
                    <li>Updates and Changes: We reserve the right to amend or update our Privacy Policy as needed to align with evolving privacy laws and best practices. The Privacy Policy will provide information on how we communicate these changes to users.</li>
                    <li>Compliance: SmartSteps is dedicated to compliance with relevant data protection regulations, and our Privacy Policy is designed to reflect these commitments.</li>
                  </ol>
                  <p>Our Privacy Policy serves as a critical component of your experience with SmartSteps, emphasizing transparency, user rights, and data security. It is your responsibility to familiarize yourself with the specifics of our Privacy Policy and to ensure that you are comfortable with the practices outlined within it. Your trust and privacy are of paramount importance to us, and we are committed to upholding the highest standards in data protection and user privacy within the SmartSteps community.</p>
                  <h5>8. Changes to Terms</h5>
                  <p>SmartSteps, in its commitment to providing the best possible service to its users, maintains the right to modify or revise these Terms of Use at any given time. We recognize the importance of transparency and want to ensure that you are fully aware of the implications of any changes made to these Terms. Here's an expanded explanation of this clause:</p>
                  <ol type='a' className='p-3'>
                    <li>Flexibility and Adaptability: We understand that the digital landscape, as well as our services, may evolve over time. To adapt to these changes and to better serve our users, SmartSteps may find it necessary to update or revise these Terms.</li>
                    <li>Notification Efforts: Whenever we make significant changes to these Terms, we will make diligent efforts to notify you. This notification may come in the form of email communication, in-app messages, or other appropriate means. Our aim is to ensure that you are informed about the modifications we've made.</li>
                    <li>Responsibility to Stay Informed: While we strive to notify you of changes, it is also your responsibility as a user to stay informed. The best way to do this is by regularly reviewing these Terms, which are readily accessible on our platform. By doing so, you can remain up-to-date with any adjustments that may affect your use of SmartSteps.</li>
                    <li>Continued Use Signifies Acceptance: It's important to note that by continuing to use SmartSteps after changes to these Terms have been posted, you are indicating your acceptance of those changes. We encourage you to carefully review any amendments to ensure that they align with your continued use of our platform.</li>
                    <li>Your Right to Choose: We believe in giving you the freedom to choose whether or not you agree with the updated Terms. If you find that the changes do not align with your preferences or expectations, you have the option to discontinue your use of SmartSteps.</li>
                    <li>Open for Feedback: Your feedback and input are valuable to us. If you have concerns or questions about any modifications made to these Terms, we encourage you to reach out to us. We are here to address your inquiries and provide clarification as needed.</li>
                    <li>Commitment to Fairness: Our commitment to transparency and fairness extends to our revision process. We aim to ensure that any changes made are in the best interest of our users and the SmartSteps community as a whole.</li>
                  </ol>
                  <p>At SmartSteps, we view these Terms as a dynamic framework that can adapt to better serve you and our growing community. Our goal is to maintain an open and trustworthy relationship with our users, and we believe that providing you with notice of changes and the opportunity to review and accept them is essential in achieving that objective. Your continued use of SmartSteps signifies your ongoing agreement with our Terms and your commitment to being a valued member of our community.</p>
                  <h5>9. Termination</h5>
                  <p>SmartSteps is committed to maintaining a safe and enjoyable environment for all users of our platform. To uphold these standards and ensure the well-being of our community, we reserve the right to take specific actions, such as the termination or suspension of your access to the SmartSteps platform, under certain circumstances. Here's a detailed expansion of this clause:</p>
                  <ol type='a' className='p-3'>
                    <li>Protecting the SmartSteps Community: Our primary goal is to create a positive and secure environment for all SmartSteps users. We do this to safeguard the interests and experiences of our community members.</li>
                    <li>Termination or Suspension: We hold the authority to terminate or suspend your access to the SmartSteps platform. This action may be taken without prior notice. The decision to do so is made at our discretion.</li>
                    <li>Reasons for Termination or Suspension: Access may be terminated or suspended for various reasons, including but not limited to the following:
                      <ul className='p-3'>
                        <li>Breach of Terms: If you violate any of the terms outlined in our Terms of Use or Privacy Policy, your access may be suspended or terminated. This includes engaging in prohibited activities, fraudulent behavior, or any other actions that are not in line with our guidelines.</li>
                        <li>Community Safety: If your actions or behavior within the SmartSteps community pose a threat to the safety, security, or well-being of other users, we may take action to protect the interests of our community.</li>
                        <li>Compliance with Legal Obligations: In some cases, compliance with legal obligations or requests from law enforcement agencies may necessitate the suspension or termination of access to our platform.</li>
                      </ul>
                    </li>
                    <li>No Prior Notice: It's important to note that in certain situations, we may exercise our right to suspend or terminate your access without prior notice. This decision will be made based on the specific circumstances and the severity of the breach or violation.</li>
                    <li>Balancing Community Interests: Our actions are aimed at striking a balance between the interests of individual users and the broader SmartSteps community. We take these measures to maintain the integrity of our platform and ensure a positive experience for all.</li>
                    <li>Appeals and Inquiries: If you believe that your access has been unfairly suspended or terminated, or if you have questions about the reasons for such actions, we encourage you to reach out to us. We are open to reviewing individual cases and providing explanations or solutions as appropriate.</li>
                    <li>Continuous Improvement: Our commitment to the SmartSteps community includes a dedication to continuous improvement. We strive to refine our processes and policies to better serve our users and maintain a secure and enjoyable platform.</li>
                    
                  </ol>
                  <p>At SmartSteps, we take the responsibility of protecting our community seriously. While it is our hope that such actions are rarely necessary, we reserve the right to take them when warranted to uphold the principles of fairness, safety, and integrity within our platform. We appreciate your understanding and cooperation in helping us maintain a thriving and secure SmartSteps community.</p>
                  <h5>10. Contact Us</h5>
                  <p>We value your feedback and questions. Should you have any inquiries or concerns regarding these Terms or your SmartSteps experience, please do not hesitate to reach out to us at <a href='mailto:hello@smartsteps.shop'></a>hello@smartsteps.shop</p>
                  <p>By choosing to use SmartSteps, you are acknowledging that you have read, comprehended, and agreed to abide by these Terms and our Privacy Policy. If you find yourself in disagreement with any part of these Terms, we kindly request that you discontinue your use of SmartSteps. Thank you for being a part of our active and vibrant community.</p>


                  <hr className="mb-4 mt-4" />
                  <button onClick={()=>this.setState({register_modal_show:false})} className="main_btn" style={{backgroundColor:'transparent'}}>Cancel</button>
                  <button onClick={this.doJoinNow} disabled={this.state.loading} className="main_btn float-right">Confirm</button>
                
                </div>
              </div>
            </Modal.Body>            
          </Modal>

          {/* <div className="modal fade" id="withdrawModal">
            <div className="modal-dialog ">
              <div className="modal-content">
                <div className="modal_top_bg">
                  <img className="modal_logo" src="img/logo.png" alt="" />
                  <h4 className="modal-title">Our system is currently under maintenance.</h4>
                  <button type="button" className="close" data-dismiss="modal"><img src="img/close.png" /></button>
                </div>
            
                <div className="modal-body text-center">
                  <p>So, there might be some delays in our contract transactions until 3 PM GMT. </p>
                  <p>We sincerely appreciate your patience.</p>
                </div>
                
              </div>
            </div>
          </div> */}

        </Frame>
      );
    }
  }

  const useStyles = {
    root: {
      flexGrow: 1
    }
  }


  export default withRouter(withStyles(useStyles)(withSnackbar(Home)));
  //export default Home;
